<template>
  <news-list :noticeClass="noticeClass" />
</template>
<script>
import NewsList from "./news-list";

export default {
  name: "news-news",
  components: {
    NewsList,
  },
  data() {
    return {
      noticeClass: 3,
    };
  }
};
</script>

<style lang="scss" scoped>
</style>